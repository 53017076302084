import { Sidebar, MoneyFilledIcon, MoneyOutlinedIcon, scrollIntoView } from '@flock/shared-ui'
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
  Typography,
  Switch,
  debounce,
  FormControlLabel,
} from '@mui/material'

import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import theme from '@flock/shared-ui/src/theme/theme'
import {
  CompoundingGrowth,
  DEFAULT_CLOSING_AND_LEGAL_FEE,
  DEFAULT_DISTRIBUTION_FACTOR,
  DEFAULT_FUND_EQUITY_PERCENT_TARGET,
  DEFAULT_ONBOARDING_FEE,
  FlockCalculatorInputs,
  formatIntegerDollars,
  SelfManageCalculatorInputs,
  sellVersusFlockCalculator,
} from '@flock/utils'
import { useInView } from '../utils'
import SectionLayout from '../SharedComponents/SectionLayout'
import RentalPropertyCalculatorSalesGraph from './RentalPropertyCalculatorSalesGraph'
import RentalPropertyCalculatorSalesTable from './RentalPropertyCalculatorSalesTable'
import ControlledTextField from '../CashFlowComponents/ControlledTextField'

const defaultInputs = {
  propertyValue: 400000,
  monthlyRent: 2500,
  remainingMortgageAmount: 60000,
  equityTakeout: 0,
  onboardingFee: DEFAULT_ONBOARDING_FEE,
  mortgageInterestRate: 0.04,
  mortgageYearsRemaining: 10,
  vacancyRate: 0.05,
  capexCosts: 0,
  annualTurnCost: 3500,
  monthlyMaintenanceCost: 200,
  insurance: 300,
  leasingCommissions: 600,
  propertyTaxes: 2400,
  propertyManagementFees: 0.1,
  capitalReserve: 200,
  hpa: 0.03,
  rentGrowth: 0.02,
  currentHouseValue: 400000,
  purchasePrice: 200000,
  dateOfPurchase: new Date(),
  outstandingMortgageAmount: 60000,
  purchaseYear: 2004,
}

const sectionIds = ['overview', 'sell']

const RentalPropertyCalculatorContent = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const [activeStep, setActiveStep] = useState(0)

  const [sellingNetValue, setSellingNetValue] = useState(0)
  const [contributingNetValue, setContributingNetValue] = useState(0)

  const [sellingCosts, setSellingCosts] = useState<any>()
  const [flockCosts, setFlockCosts] = useState<any>()

  const [calculatedMonthlyRent, setCalculatedMonthlyRent] = useState(2500)
  const [calculatedPropertyValue, setCalculatedPropertyValue] = useState(400000)

  const { control, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultInputs,
      hasMortgage: defaultInputs.remainingMortgageAmount > 0,
    },
  })

  const fields = useWatch({ control })

  const sideBarItems = [
    {
      id: 'overview',
      title: 'Overview.',
      description: 'See how much you can make with Flock.',
    },
    {
      id: 'sell',
      title: 'Sell.',
      description:
        flockCosts && sellingCosts && contributingNetValue > sellingNetValue
          ? `If you sell for ${formatIntegerDollars(
              calculatedPropertyValue
            )}, you'll keep ${formatIntegerDollars(
              contributingNetValue - sellingNetValue
            )} more with Flock.`
          : `See what you can keep with Flock.`,
    },
  ]

  const currentSection = useInView(sectionIds)

  const onChange = (newTab: number) => {
    scrollIntoView(sectionIds[newTab], -100)
  }

  useEffect(() => {
    setActiveStep(sectionIds.indexOf(currentSection))
  }, [currentSection])

  const updateFields = (newFields: any) => {
    const {
      propertyValue,
      remainingMortgageAmount,
      mortgageYearsRemaining,
      mortgageInterestRate,
      hpa,
      rentGrowth,
      vacancyRate,
      annualTurnCost,
      monthlyMaintenanceCost,
      propertyManagementFees,
      propertyTaxes,
      insurance,
      capitalReserve,
      leasingCommissions,
    } = newFields

    const monthlyRent = newFields.monthlyRent || 0

    const selfManageInputs: SelfManageCalculatorInputs = {
      propertyValue,
      monthlyRent,
      remainingMortgageAmount,
      mortgageYearsRemaining,
      mortgageInterestRate,
      hpa,
      rentGrowth,
      vacancyRate,
      annualTurnCost,
      monthlyMaintenanceCost,
      propertyManagementFees,
      propertyTaxes,
      insurance,
      capitalReserve,
      leasingCommissions,
      monthlyHoaFees: 0,
      monthlyUtilities: 0,
    }

    const { onboardingFee, capexCosts } = newFields

    const flockInputs: FlockCalculatorInputs = {
      propertyValue,
      remainingMortgageAmount,
      hpa,
      flockDistributionRate: DEFAULT_DISTRIBUTION_FACTOR,
      closingAndLegalFee: DEFAULT_CLOSING_AND_LEGAL_FEE,
      reinvestDistributions: false,
      equityTakeout: 0,
      fundEquityPercentTarget: DEFAULT_FUND_EQUITY_PERCENT_TARGET,
      hpaCompounding: CompoundingGrowth.QUARTERLY,
      onboardingFee,
      capexCosts,
    }

    if (!newFields.hasMortgage) {
      selfManageInputs.remainingMortgageAmount = 0
      selfManageInputs.mortgageYearsRemaining = 0
      selfManageInputs.mortgageInterestRate = 0
      flockInputs.remainingMortgageAmount = 0
    }
    // Don't update the fields if the field value is invalid or undefined
    const isValidInput =
      !Object.keys(selfManageInputs).some((key: string) =>
        Number.isNaN(selfManageInputs[key as keyof SelfManageCalculatorInputs])
      ) &&
      !(
        newFields.hasMortgage &&
        remainingMortgageAmount &&
        !mortgageYearsRemaining
      )

    if (!isValidInput) {
      return
    }

    const { purchasePrice, dateOfPurchase } = newFields

    const calculatorInputs: any = {
      currentHouseValue: propertyValue,
      purchasePrice,
      dateOfPurchase,
      monthlyRent: calculatedMonthlyRent,
      outstandingMortgageAmount: 0,
    }

    if (newFields.hasMortgage) {
      calculatorInputs.outstandingMortgageAmount = remainingMortgageAmount
    }

    const calculatorOutput = sellVersusFlockCalculator(calculatorInputs)
    const { selling, contributingToFlock } = calculatorOutput
    setSellingNetValue(selling.netValue)
    setContributingNetValue(contributingToFlock.netValue)

    setSellingCosts(selling)
    setFlockCosts(contributingToFlock)

    setCalculatedMonthlyRent(monthlyRent || 0)
    setCalculatedPropertyValue(propertyValue)
  }

  const debounceUpdateFields = useMemo(() => debounce(updateFields, 1000), [])

  useEffect(() => {
    debounceUpdateFields(fields)
  }, [fields, debounceUpdateFields])

  useEffect(() => {
    // Set rent-dependent values
    setValue('insurance', 12 * 0.01 * calculatedMonthlyRent || 0)
    setValue('propertyTaxes', 12 * 0.08 * calculatedMonthlyRent || 0)
    setValue('leasingCommissions', 12 * 0.02 * calculatedMonthlyRent || 0)
  }, [calculatedMonthlyRent, setValue])

  return (
    <SectionLayout
      name="rental-property-calculator"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      {!isTablet && (
        <Grid
          sx={{
            position: 'sticky',
            top: '3%',
            height: 'fit-content',
            zIndex: 999,
            marginBottom: '-350px',
          }}
          item
          xs={3}
          display="flex"
          justifyContent="flex-end"
        >
          <Box width="100%" pt="0px" pb="80px">
            <Sidebar
              items={sideBarItems}
              onChange={onChange}
              selected={activeStep}
            />
          </Box>
        </Grid>
      )}
      {isMobile && (
        <Grid
          sx={{
            position: 'sticky',
            top: '64px',
            height: 'fit-content',
            zIndex: 999,
            paddingTop: '0px',
          }}
          container
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            sx={{
              backgroundColor: 'trustBlue.main',
              paddingTop: '16px',
              paddingBottom: '16px',
            }}
          >
            <Button
              style={{
                borderRadius: 24,
                backgroundColor: 'white',
                fontSize: '18px',
                color: 'black',
                marginLeft: '4px',
                paddingLeft: '16px',
                paddingRight: '12px',
                textAlign: 'left',
              }}
              variant="contained"
              onClick={() => scrollIntoView(sectionIds[1], -175)}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="h3">Sell.</Typography>
                {flockCosts &&
                  sellingCosts &&
                  contributingNetValue > sellingNetValue && (
                    <Typography variant="h3">
                      {formatIntegerDollars(Math.round(contributingNetValue))}{' '}
                      net income.
                    </Typography>
                  )}
              </Box>
            </Button>
          </Box>
        </Grid>
      )}
      <Grid
        container
        item
        xs={isTablet ? 12 : 9}
        direction="column"
        sx={{
          backgroundColor: undefined,
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap="32px"
          pb="32px"
          id="overview"
        >
          <Box position="absolute" top="0" />
          <Box
            pb="0px"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <MoneyFilledIcon height="28px" width="28px" />
            {!isMobile && (
              <Grid item>
                <Typography sx={{ marginLeft: '1rem' }}>
                  My house is worth
                </Typography>
              </Grid>
            )}
            <Grid item sx={{ width: '150px', marginLeft: '1rem' }}>
              <ControlledTextField
                name="propertyValue"
                label=""
                format="dollars"
                control={control}
                paddingTop="0px"
              />
            </Grid>
            {isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}>house value</Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <MoneyOutlinedIcon height="28px" width="28px" />
            {!isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}> I charge</Typography>
            )}
            <Grid item sx={{ width: '150px', marginLeft: '1rem' }}>
              <ControlledTextField
                name="monthlyRent"
                label=""
                format="dollars"
                control={control}
                paddingTop="0px"
              />
            </Grid>
            {!isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}>
                per month in rent
              </Typography>
            )}
            {isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}>/mo rent</Typography>
            )}
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: '16px',
              }}
            >
              <FormControlLabel
                label=""
                sx={{ marginRight: '0px' }}
                control={
                  <Controller
                    name="hasMortgage"
                    control={control}
                    render={({ field: fieldProps }) => (
                      <Grid item xs={12}>
                        <Box sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          <Switch
                            {...fieldProps}
                            checked={fieldProps.value}
                            onChange={(e) =>
                              fieldProps.onChange(e.target.checked)
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Box>
                      </Grid>
                    )}
                  />
                }
              />
              <Typography>I have a mortgage</Typography>
            </Box>
            {fields.hasMortgage && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'left' : 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '150px' }}>
                      <ControlledTextField
                        name="remainingMortgageAmount"
                        label=""
                        format="dollars"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography
                      sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      mortgage
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '70px' }}>
                      <ControlledTextField
                        name="mortgageYearsRemaining"
                        label=""
                        format="number"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography
                      sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      years left at
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '75px' }}>
                      <ControlledTextField
                        name="mortgageInterestRate"
                        label=""
                        format="decimalPercent"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography sx={{ marginLeft: '1rem' }}>
                      interest
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'left' : 'center',
                    paddingTop: '16px',
                    paddingBottom: '32px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item sx={{ width: '150px' }}>
                      <ControlledTextField
                        name="purchasePrice"
                        label=""
                        format="dollars"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography
                      sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      purchase price {isMobile ? '' : 'in'}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '100px' }}>
                      <ControlledTextField
                        name="purchaseYear"
                        label=""
                        format="year"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    {isMobile && (
                      <Typography sx={{ marginLeft: '1rem' }}>
                        purchase year
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>

      {!isTablet && <Grid item xs={3} sx={undefined} />}
      <Grid
        item
        xs={isTablet ? 12 : 9}
        sx={{
          backgroundColor: undefined,
        }}
      >
        {isMobile && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gap="32px"
            id="sell"
            sx={undefined}
          >
            <Box position="absolute" mt="-100px" />
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap="32px"
          pt="0px"
          pb="80px"
          id={isMobile ? undefined : 'sell'}
          sx={undefined}
        >
          {!isMobile && <Box mt="-228px" position="absolute" />}
          <Grid item xs={12}>
            <Box
              padding="24px"
              borderRadius="24px"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              gap="16px"
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
              }}
            >
              {isMobile && (
                <>
                  <Typography variant="h2">Sell.</Typography>
                  {flockCosts &&
                    sellingCosts &&
                    contributingNetValue > sellingNetValue && (
                      <>
                        <Typography color="#136D42" variant="h3">
                          {formatIntegerDollars(
                            Math.round(contributingNetValue)
                          )}
                        </Typography>
                        <Typography variant="body1">
                          Keep{' '}
                          {formatIntegerDollars(
                            Math.round(contributingNetValue) -
                              Math.round(sellingNetValue)
                          )}{' '}
                          more when you sell for{' '}
                          {formatIntegerDollars(calculatedPropertyValue)} by
                          using Flock.
                        </Typography>
                      </>
                    )}
                </>
              )}
              <Typography>
                Selling your investment property the traditional way can be
                expensive. There are costs to ready the property, stage and sell
                often with a realtor, not to mention the tax bills that come
                after.
              </Typography>
            </Box>
          </Grid>
          {sellingCosts && flockCosts && (
            <RentalPropertyCalculatorSalesGraph
              selling={sellingCosts}
              contributingToFlock={flockCosts}
            />
          )}
          {sellingCosts && flockCosts && (
            <RentalPropertyCalculatorSalesTable
              selling={sellingCosts}
              contributingToFlock={flockCosts}
            />
          )}
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default RentalPropertyCalculatorContent
