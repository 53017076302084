import { Box, Grid, Typography } from '@mui/material'

import React from 'react'
import SellVsFlockComparisonSection from '../SellVersusFlockComponents/SellVsFlockComparisonSection'

type RentalPropertyCalculatorSalesGraphProps = {
  selling: {
    currentHouseValue: number
    salesCosts: number
    holdingCosts: number
    stagingCosts: number
    otherCosts: number
    capitalGainsTaxLiability: number
    depreciationRecaptureTaxLiability: number
    taxLiability: number
    mortgagePayoff: number
    lostRent: number
    netValue: number
    netFees: number
  }
  contributingToFlock: {
    currentHouseValue: number
    contributingCosts: number
    mortgagePayoff: number
    netValue: number
    netFees: number
  }
}

const RentalPropertyCalculatorSalesGraph = (
  props: RentalPropertyCalculatorSalesGraphProps
) => {
  const { selling, contributingToFlock } = props

  return (
    <Grid item xs={12}>
      <Box
        padding="24px"
        borderRadius="24px"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        gap="16px"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
        }}
      >
        <Typography variant="h3">Potential Sale Costs</Typography>
        <Grid item xs={12} container spacing={4}>
          <SellVsFlockComparisonSection
            sellingNetValue={selling.netValue}
            contributingNetValue={contributingToFlock.netValue}
            traditionalSaleText="net proceeds"
            contributeToFlockText="of equity in the Flock portfolio"
            rentalPropertyCalc
          />
        </Grid>
      </Box>
    </Grid>
  )
}

export default RentalPropertyCalculatorSalesGraph
