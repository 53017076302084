import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const MoneyOutlinedIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M32 4C16.5375 4 4 16.5375 4 32C4 47.4625 16.5375 60 32 60C47.4625 60 60 47.4625 60 32C60 16.5375 47.4625 4 32 4ZM32 55.25C19.1625 55.25 8.75 44.8375 8.75 32C8.75 19.1625 19.1625 8.75 32 8.75C44.8375 8.75 55.25 19.1625 55.25 32C55.25 44.8375 44.8375 55.25 32 55.25ZM34.9813 30.55L33.3937 30.1812V21.7875C35.7687 22.1125 37.2375 23.6 37.4875 25.425C37.5187 25.675 37.7313 25.8563 37.9813 25.8563H40.7875C41.0812 25.8563 41.3125 25.6 41.2875 25.3062C40.9062 21.4125 37.7 18.9125 33.4188 18.4813V16.4375C33.4188 16.1625 33.1938 15.9375 32.9188 15.9375H31.1625C30.8875 15.9375 30.6625 16.1625 30.6625 16.4375V18.5C26.2375 18.9312 22.775 21.375 22.775 25.9375C22.775 30.1625 25.8875 32.2 29.1562 32.9813L30.7 33.375V42.2938C27.9375 41.925 26.3875 40.45 26.0688 38.4625C26.0312 38.225 25.8188 38.05 25.575 38.05H22.6875C22.3937 38.05 22.1625 38.3 22.1875 38.5938C22.4688 42.0312 25.075 45.1937 30.6375 45.6V47.5625C30.6375 47.8375 30.8625 48.0625 31.1375 48.0625H32.9125C33.1875 48.0625 33.4125 47.8375 33.4125 47.5563L33.4 45.575C38.2938 45.1437 41.7938 42.525 41.7938 37.825C41.7875 33.4875 39.0312 31.55 34.9813 30.55ZM30.6938 29.5375C30.3438 29.4375 30.05 29.3438 29.7563 29.225C27.6438 28.4625 26.6625 27.2312 26.6625 25.6437C26.6625 23.375 28.3813 22.0813 30.6938 21.7875V29.5375ZM33.3937 42.3125V33.9562C33.5875 34.0125 33.7625 34.0562 33.9437 34.0938C36.9 34.9937 37.8937 36.2437 37.8937 38.1625C37.8937 40.6062 36.0562 42.075 33.3937 42.3125Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default MoneyOutlinedIcon
