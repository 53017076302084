import { Box, Grid } from '@mui/material'
import React from 'react'
import SellVsFlockTable from '../SellVersusFlockComponents/SellVsFlockTable'

type RentalPropertyCalculatorSalesTableProps = {
  selling: {
    currentHouseValue: number
    salesCosts: number
    holdingCosts: number
    stagingCosts: number
    otherCosts: number
    capitalGainsTaxLiability: number
    depreciationRecaptureTaxLiability: number
    taxLiability: number
    mortgagePayoff: number
    lostRent: number
    netValue: number
    netFees: number
  }
  contributingToFlock: {
    currentHouseValue: number
    contributingCosts: number
    mortgagePayoff: number
    netValue: number
    netFees: number
  }
}

const RentalPropertyCalculatorSalesTable = (
  props: RentalPropertyCalculatorSalesTableProps
) => {
  const { selling, contributingToFlock } = props

  return (
    <Grid item xs={12}>
      <Box
        padding="24px"
        borderRadius="24px"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        gap="16px"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
        }}
      >
        <SellVsFlockTable
          sellCosts={selling}
          flockCosts={contributingToFlock}
          rentalPropertyCalc
        />
      </Box>
    </Grid>
  )
}

export default RentalPropertyCalculatorSalesTable
